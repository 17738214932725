<template>
    <div>
        <div class="pop-node" v-if="false" :style="{top:comp.pop.y+'px', left:comp.pop.x+'px', width:comp.pop.width+'px'}">
            <div class="pop-close" @click="comp.pop.close"><i class="far fa-times-circle"></i></div>
            <table class="tbl-info">
                <tr>
                    <th>대상</th>
                    <td>
                        <select>
                            <option>선택</option>
                            <option>피고인</option>
                            <option>피해자</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th>대체 어구</th>
                    <td><input type="text" vlaue=""></td>
                </tr>
                <tr>
                    <th>시작시간</th>
                    <td>
                        <!-- <v-date-picker v-model="range" is-range>
                        </v-date-picker> -->
                    </td>
                </tr>
                <tr>
                    <th>종료시간</th>
                    <td><input type="text" vlaue=""></td>
                </tr>
            </table>

        </div>
        <div class="keti-title">
            <div class="keti-type">판결문</div>
            의정부지방법원 2017. 6. 21. 선고 2017고합81 판결 [살인미수]
        </div>
        <div id="ketiEditor" name="ketiEditor" ref="editorhtml" v-html="comp.contents" class="keti-editor" contentEditable="false" @click="comp.click" @scroll="comp.handleScroll">
        </div>
        <div class="keti-subeditor" v-if="comp.pop.is_show">
            <div class="title-box">
                속성
            </div>
            <table class="tbl-info">
                <colgroup>
                    <col width="100">
                    <col width="*">
                </colgroup>
                <tr>
                    <th>구분</th>
                    <td>{{comp.snode.toulmin_code}}</td>
                </tr>
                <tr>
                    <th>원문어구</th>
                    <td>{{comp.snode.text}}</td>
                </tr>
                <tr>
                    <th>대체어구</th>
                    <td><input type="text" v-model="comp.snode.trans"></td>
                </tr>
                <tr>
                    <th>행위자</th>
                    <td>
                        <select v-model="comp.snode.target">
                            <option value="">선택</option>
                            <option>피고인</option>
                            <option>피해자</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th>핵심 키워드</th>
                    <td><input type="text" v-model="comp.snode.keyword"></td>
                </tr>
                <tr>
                    <th>시작시간</th>
                    <td>
                        <CarrotDateTimePicker v-model="comp.snode.stime"></CarrotDateTimePicker>
                    </td>
                </tr>
                <tr>
                    <th>종료시간</th>
                    <td>
                        <CarrotDateTimePicker v-model="comp.snode.etime"></CarrotDateTimePicker>
                    </td>
                </tr>
                <tr>
                    <th>장소</th>
                    <td><input type="text" v-model="comp.snode.keyword"></td>
                </tr>
                <tr>
                    <th>쟁점번호</th>
                    <td><input type="text" v-model="comp.snode.keyword"></td>
                </tr>
            </table>
            <div class="box_button">
                <button class="btn" @click="comp.delNode">삭제</button>
            </div>
        </div>
        <div class="keti-subeditor" v-if="!comp.pop.is_show">
            <div class="title-box">
                목록
            </div>
            <div class="info-box">
                <table class="tbl-list">
                    <colgroup>
                        <col width="100">
                        <col width="*">
                        <col width="100">
                    </colgroup>
                    <tr>
                        <th>구분</th>
                        <th>주장</th>
                        <th>기능</th>
                    </tr>
                    <tbody>
                        <template v-for="(node,idx) in comp.knode.list" :key="idx">
                        <tr v-if="node.ctype=='C'" @click="comp.setToulmin(node)">
                            <td class="cat"> <i v-if="node.id==comp.pnode.id" class="fas fa-angle-right"></i>  {{node.toulmin_code}}</td>
                            <td><span class="node-text">{{node.trans}}</span></td>
                            <td class="cat" @click="comp.showToulmin(node)"><button type="button" class="btn">툴민</button></td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="title-box">
                툴민요소
            </div>
            <div class="tools">
                <button class="btnBox btnBoxC" @click="comp.setNode('C')">주장<br>Claim</button>
                <button class="btnBox btnBoxW" @click="comp.setNode('W')">보장<br>Warrant</button>
                <button class="btnBox btnBoxB" @click="comp.setNode('B')">뒷받침<br>Backing</button>

                <button class="btnBox btnBoxR" @click="comp.setNode('R')">반박<br>Rebuttal</button>
                <button class="btnBox btnBoxRS" @click="comp.setNode('RS')">반박보강<br>RSupport</button>
                <button class="btnBox btnBoxD" @click="comp.setNode('D')">기초사실<br>Datum</button>

                <button class="btnBox btnBoxD" @click="comp.reset()">Reset<br>(Reset)</button>
                <button class="btnBox btnBoxD" @click="comp.reOrder()">정렬<br>(Test)</button>
            </div>
        </div>
        <div class="keti-chart" v-show="comp.flagToulmin==true" @click="comp.flagToulmin=false">
            <table class="keti-chart-table">
                <colgroup>
                    <col width="50%">
                    <col width="50%">
                </colgroup>
                <tr>
                    <td colspan="2">
                        <div class="keti-chart-pos-C">
                            <template v-for="(n, idx) in comp.tnode.list"  :key="idx">
                                <div v-if="n.ctype=='C'" class="keti-chart-node btnBoxC">{{n.trans}}</div>
                            </template>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="infoIcon" v-if="comp.tnode.count.w>0">
                            <i class="fas fa-arrow-up"></i>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="keti-chart-pos-W">
                            <template v-for="(n, idx) in comp.tnode.list"  :key="idx">
                                <div v-if="n.ctype=='W'" class="keti-chart-node btnBoxW">{{n.trans}}</div>
                            </template>
                        </div>
                        <div class="infoIcon" v-if="comp.tnode.count.b>0">
                            <i class="fas fa-arrow-up"></i>
                        </div>
                        <div class="keti-chart-pos-B">
                            <template v-for="(n, idx) in comp.tnode.list"  :key="idx">
                                <div v-if="n.ctype=='B'" class="keti-chart-node btnBoxB">{{n.trans}}</div>
                            </template>
                        </div>
                    </td>
                    <td>
                        <div class="keti-chart-pos-R">
                            <template v-for="(n, idx) in comp.tnode.list"  :key="idx">
                                <div v-if="n.ctype=='R'" class="keti-chart-node btnBoxR">{{n.trans}}</div>
                            </template>
                        </div>
                        <div class="infoIcon" v-if="comp.tnode.count.rs>0">
                            <i class="fas fa-arrow-up"></i>
                        </div>
                        <div class="keti-chart-pos-RS">
                            <template v-for="(n, idx) in comp.tnode.list"  :key="idx">
                                <div v-if="n.ctype=='RS'" class="keti-chart-node btnBoxRS">{{n.trans}}</div>
                            </template>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="keti-chart-pos-D">
                            <template v-for="(n, idx) in comp.tnode.list"  :key="idx">
                                <div v-if="n.ctype=='D'" class="keti-chart-node btnBoxD">{{n.trans}}</div>
                            </template>
                        </div>
                    </td>
                </tr>

            </table>

        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive, watch, ref } from 'vue'
import axios from '@/plugins/axios.js';

import CarrotDateTimePicker from '@/components/common/CarrotDateTimePicker.vue'


export default {
    name: 'TMEditor',
    components: {
        CarrotDateTimePicker
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        etype: {
            type: String,
            default: "temp",
        },
    },
    emits: [ 'update:modelValue' ],
    setup(props, {emit}) {
        // {"ctype": tp, "text": sel.toString(), "trans": sel.toString(), "id":cnode.id, "toulmin":pid}
        const editorhtml = ref(null);
        const comp = reactive({
            editor: null,
            lastid: 1,
            contents : "",
            flagToulmin: false,
            toulmin_element : [
                {code:"C", text:"주장", class:"node-c", button:"btnBoxC"},
                {code:"W", text:"보장", class:"node-w", button:"btnBoxW"},
                {code:"B", text:"뒷받침", class:"node-b", button:"btnBoxB"},
                {code:"R", text:"반박", class:"node-r", button:"btnBoxR"},
                {code:"RS", text:"반박보강", class:"node-rs", button:"btnBoxRS"},
                {code:"D", text:"기초사실", class:"node-d", button:"btnBoxD"}
            ],
            pop: {
                x: '0px',
                y: '0px',
                width: 400,
                is_show: false,
                close: () => {
                    comp.pop.is_show = false;
                }
            },
            snode : {
                ctype: "",
                text: "",
                target: "",
                index: "",
                id: ""
            },
            pnode : {
                ctype: "",
                text: "",
                index: "",
                id: ""
            },
            knode : {
                list: [],
                count: {
                    c:0,
                    w:0,
                    b:0,
                    r:0,
                    rs:0,
                    d:0,
                }
            },
            tnode : {
                list: [],
                count: {
                    c:0,
                    w:0,
                    b:0,
                    r:0,
                    rs:0,
                    d:0,
                }
            },
            handleScroll: () => {
                comp.pop.is_show = false;
            },
            click: (event) => {
                if(event.target.id.substring(0, 4)=='node') {
                    for(let i=0;i<comp.knode.list.length;i++) {
                        if(comp.knode.list[i].id == event.target.id) {
                            comp.snode = comp.knode.list[i];
                        }
                    }
                    comp.pop.is_show = true;
                } else {
                    comp.pop.is_show = false;
                }
                
            },
            check: (event) => {
                if(event.metaKey) return true; return false;
            },
            update: () => {
                emit('update:modelValue', comp.contents);
            },
            reset: () => {
                localStorage.removeItem("ed_content");
                localStorage.removeItem("ed_node");
                localStorage.removeItem("lastid");
                
            },
            showToulmin: () => {
                comp.flagToulmin = true;
            },
            setToulmin: (node) => {
                comp.tnode.list = [];
                comp.tnode.count = {
                    c:0,
                    w:0,
                    b:0,
                    r:0,
                    rs:0,
                    d:0,
                };
                for(let i=0;i<comp.knode.list.length;i++) {
                    if(comp.knode.list[i].toulmin==node.id) {
                        comp.tnode.list.push(comp.knode.list[i]);
                        if(comp.knode.list[i].ctype=='C') {
                            comp.tnode.count.c++;
                        }
                        if(comp.knode.list[i].ctype=='W') {
                            comp.tnode.count.w++;
                        }
                        if(comp.knode.list[i].ctype=='B') {
                            comp.tnode.count.b++;
                        }
                        if(comp.knode.list[i].ctype=='R') {
                            comp.tnode.count.r++;
                        }
                        if(comp.knode.list[i].ctype=='RS') {
                            comp.tnode.count.rs++;
                        }
                        if(comp.knode.list[i].ctype=='D') {
                            comp.tnode.count.d++;
                        }
                    }
                }
                comp.pnode = node;
                let element = document.getElementById(node.id);
                let rect = element.getBoundingClientRect();
                let rect_editor = editorhtml.value.getBoundingClientRect();
                let scroll_top = rect.top + editorhtml.value.scrollTop;

                const scroll_padding = 20 + rect_editor.top;
                editorhtml.value.scrollTop = (scroll_top - scroll_padding<0 ? 0 : scroll_top - scroll_padding);
            },
            setNode: (tp) => {
                var sel = document.getSelection();
                let txt = sel.toString().trim();
                if (sel.rangeCount && sel.getRangeAt && txt!="") {
                    if(sel.focusNode.parentNode.className=='keti-editor') {
                        comp.lastid = parseInt(comp.lastid) + 1;
                        let range = sel.getRangeAt(0);
                        sel.removeAllRanges();
                        sel.addRange(range);
                        let cnode = document.createElement("span");
                        cnode.id = "node" + comp.lastid
                        let ntext = "";
                        let pid = comp.pnode.id;
                        if(tp=="C") {
                            cnode.className="node-c";
                            comp.knode.count.c++;
                            ntext = "주장";
                            pid = cnode.id
                        } else if(tp=="W") {
                            cnode.className="node-w";
                            comp.knode.count.w++;
                            ntext = "보장";
                        } else if(tp=="B") {
                            cnode.className="node-b";
                            comp.knode.count.b++;
                            ntext = "뒷받침";
                        } else if(tp=="R") {
                            cnode.className="node-r";
                            comp.knode.count.r++;
                            ntext = "반박";
                        } else if(tp=="RS") {
                            cnode.className="node-rs";
                            comp.knode.count.rs++;
                            ntext = "반박보강";
                        } else if(tp=="D") {
                            ntext = "기초사실";
                            cnode.className="node-d";
                            comp.knode.count.d++;
                        } else {
                            cnode.className="red";
                        }
                        comp.knode.list.push({"ctype": tp, "text": sel.toString(), "trans": sel.toString(), "id":cnode.id, "toulmin":pid, "target":''});
                        if(tp=="C") {
                            comp.pnode = comp.knode.list[comp.knode.list.length-1];
                        }
                        range.surroundContents(cnode);
                        sel.removeAllRanges();
                        const element = document.getElementById(cnode.id);
                        element.innerHTML = '<span class="node-desc">'+ntext+'</span>' + element.innerHTML;

                    }
                    comp.reOrder();
                }                
            },
            delNode: () => {
                const element = document.getElementById(comp.snode.id);
                var nodeLables = document.querySelectorAll('#'+comp.snode.id+' span.node-desc');
                if (nodeLables.length) {
                    Array.prototype.forEach.call(nodeLables, function (thisnode) {
                        element.removeChild(thisnode);
                    });
                }
                element.outerHTML = element.innerHTML;
                for(let i=0;i<comp.knode.list.length;i++) {
                    if(comp.knode.list[i].id == comp.snode.id) {
                        comp.knode.list.splice(i, 1);
                        break;
                    }
                }
                comp.pop.is_show = false;
                comp.reOrder();
            },
            transCtype: (code) => {
                let transCode = {"C" : "주장", "W" : "보장", "B":"뒷받침", "R":"반박", "RS":"반박보강", "D":"기초사실"};
                return transCode[code];
            },
            setNodeInfo: (id, key, value) => {
                for(let i=0;i<comp.knode.list.length;i++) {
                    if (comp.knode.list[i].id == id) {
                        comp.knode.list[i][key] = value;
                        break;
                    }
                }
            },
            getNodeInfo: (id, key) => {
                let val = "";
                for(let i=0;i<comp.knode.list.length;i++) {
                    if (comp.knode.list[i].id == id) {
                        val = comp.knode.list[i][key];
                    }
                }
                return val;
            },
            reOrder: () => {
                let sortable = [];
                for(let i=0;i<comp.knode.list.length;i++) {
                    let element = document.getElementById(comp.knode.list[i].id);
                    let rect = element.getBoundingClientRect();
                    comp.knode.list[i].position = rect.bottom;
                    sortable.push({"id":comp.knode.list[i].id, "parent_id":comp.knode.list[i].toulmin, "parent_idx":-1, "pos":rect.bottom, "pos_sub": (rect.height>30?0:parseInt(rect.right)), "pos_height":rect, "ctype":comp.knode.list[i].ctype, "toulmin_idx":0, "count":{}});
                }
                
                sortable.sort(function(a, b) {
                    return (a.pos==b.pos?a.pos_sub - b.pos_sub:a.pos - b.pos);
                });

                let idx = 0;
                for(let i=0;i<sortable.length;i++) {
                    if(sortable[i].ctype=="C") {
                        idx = idx + 1;
                        sortable[i].toulmin_idx = idx
                        sortable[i].count["C"] = 1;
                        sortable[i].toulmin_code = sortable[i].toulmin_idx + "-" + comp.transCtype(sortable[i].ctype) + "-1"
                        for(let j=0;j<sortable.length;j++) {
                            if(sortable[i].id == sortable[j].parent_id) {
                                sortable[j].parent_idx = i;
                            }
                        }

                    }
                }
                for(let i=0;i<sortable.length;i++) {
                    if(sortable[i].ctype!="C") {
                        if(sortable[i].parent_idx >= 0)  {
                            if(Object.prototype.hasOwnProperty.call(sortable[sortable[i].parent_idx].count, sortable[i].ctype)) {
                                sortable[sortable[i].parent_idx].count[sortable[i].ctype] = sortable[sortable[i].parent_idx].count[sortable[i].ctype] + 1;
                            } else {
                                sortable[sortable[i].parent_idx].count[sortable[i].ctype] = 1;
                            }
                            sortable[i].toulmin_idx = sortable[sortable[i].parent_idx].toulmin_idx;
                            sortable[i].toulmin_node_idx = sortable[sortable[i].parent_idx].count[sortable[i].ctype];
                            sortable[i].toulmin_code = sortable[i].toulmin_idx + "-" + comp.transCtype(sortable[i].ctype) + "-" + sortable[i].toulmin_node_idx;
                        }
                    }
                }
                for(let i=0;i<sortable.length;i++) {
                    let node = document.querySelector('#'+sortable[i].id+' span.node-desc');
                    let idx_code = sortable[i].toulmin_code;
                    node.innerHTML = idx_code;
                    comp.setNodeInfo(sortable[i].id, "sort_idx", i);
                    comp.setNodeInfo(sortable[i].id, "toulmin_idx", sortable[i].toulmin_idx);
                    comp.setNodeInfo(sortable[i].id, "toulmin_node_idx", sortable[i].toulmin_node_idx);
                    comp.setNodeInfo(sortable[i].id, "toulmin_code", sortable[i].toulmin_code);
                }
                comp.knode.list.sort(function(a, b) {
                    return a.sort_idx - b.sort_idx;
                });
                localStorage.setItem("ed_content", editorhtml.value.innerHTML);
                localStorage.setItem("ed_node", JSON.stringify(comp.knode));
                localStorage.setItem("lastid", comp.lastid);


            },
            getData: () => {
                let params = {};
                axios.get("../json/text.txt", { params : params}).then((res) => {
                    comp.contents = res.data;
                });
            }
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal){
                comp.contents  = nVal;
            }
        });

        onUnmounted(() => {
            // window.removeEventListener('scroll', comp.handleScroll);
        });

        onMounted(() => {
            // Mounted
            let content = localStorage.getItem("ed_content")
            content = (content==null?"":content);
            let node = localStorage.getItem("ed_node")
            comp.lastid = localStorage.getItem("lastid");
            comp.lastid = (comp.lastid==null?1:comp.lastid);
            if(node!=null) {
                node = JSON.parse(node);
                comp.knode = node;
            }
            comp.contents  = (props.modelValue==""?content:"");
            comp.etype     = props.etype;
            if(comp.contents=="") {
                comp.getData();
            }
            // window.addEventListener('scroll', comp.handleScroll);
        });

        return { comp, editorhtml };
    }
}

</script>
<style lang="scss" scope>
    .box_button {
        text-align: right;
        padding-top: 15px;
    }
    .tbl-attr {
        margin-top:10px;
        width: 100%;
        td {
            padding: 3px;
            input {
                width: 100%;
                outline: none;
                border: 1px solid #ccc;
            }
        }
        tr {
            th {
                color: #000;
                font-weight: 400;
                padding: 0 5px;
            }

        }
    }

    .pop-node {
        background-color: #EBE1F5;
        width: 200px;
        border: 1px solid #4F1882;
        border-radius: 3px;
        padding: 5px;
        position: absolute;
        .tbl-info {
            width: 100%;
            td {
                padding: 2px;
                input {
                    width: 100%;
                    outline: none;
                    border: 1px solid #ccc;
                }
            }
            tr {
                th {
                    color: #000;
                    font-weight: bold;
                }

            }
        }
        .pop-close {
            float: right;
            padding: 1px 3px;
            border: 0;
            cursor: pointer;
        }
    }
    .red {
        color:red;
    }
    .btnBoxC {
        background-color: #EBE1F5;
        border: 1px solid #4F1882;
    }
    .btnBoxW {
        background-color: #E6EEFC;
        border: 1px solid #0F397D;
    }
    .btnBoxB {
        background-color: #EBF3F7;
        border: 1px solid #1D3947;
    }

    .btnBoxR {
        background-color: #FCDCDC;
        border: 1px solid #750606;
    }
    .btnBoxRS {
        background-color: #FCF0E1;
        border: 1px solid #6E3C00;
    }

    .btnBoxD {
        background-color: #D7F5F3;
        border: 1px solid #03423E;
    }

    .tools {
        float: left;
        padding: 0 10px;
        .btnBox {
            width: 80px;
            cursor: pointer;
            border: 1px solid #ddd;
            border-radius: 5px;
            padding: 5px;
            margin: 5px;
            display: inline-block;
        }
        margin-right: 10px;
    }
    .keti-title {
        .keti-type {
            display: inline-block;
            border : 1px solid #333;
            border-radius: 10px;
            padding: 2px 10px;
            font-size: 15px;
        }
        position: fixed;
        top: 50px;
        padding: 10px;
        padding-left: 20px;
        font-weight: bold;
        font-size: 17px;

    }
    .keti-editor {
        float: left;
        position: fixed;
        top: 80px;
        left: 10px;
        width: calc(100% - 550px);
        height: 100%;
        box-sizing: border-box;
        
        padding: 10px;
        max-height: calc(100% - 110px);
        overflow-y: scroll;
        white-space: pre-line;
        line-height: 260%;
        box-shadow: 2px 2px 5px #333;
        margin: 10px;
        scroll-behavior: smooth;

    }
    .keti-subeditor {
        float: left;
        position: fixed;
        right: 10px;
        width: 500px; height: 100%;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 2px 2px 5px #333;
        max-height: calc(100% - 80px);
        height: 100%;
        margin: 10px;
        .tbl-info {
            width: 100%;
            td {
                padding: 2px;
                input {
                    width: 100%;
                    outline: none;
                    border: 1px solid #ccc;
                }
            }
        }

        .title-box {
            border-bottom : 1px solid #333;
            font-weight: bold;
            font-size: 18px;
        }
        .info-box {
            width: 100%;
            height: calc(100% - 200px);
            overflow-y: scroll;
            padding: 5px;
            .tbl-list {
                width: 100%;
                td, th {
                    border: 1px solid #ddd;
                    padding: 5px;
                }
                td {
                    .node-text {
                        padding: 0 10px;
                        display: block;
                        width: 300px;
                        text-overflow:ellipsis;
                        white-space:nowrap;
                        overflow: hidden;
                    }
                }
                tbody {
                    tr {
                        cursor: pointer;
                        &:hover {
                            td {
                                background-color: #ddd;
                            }
                        }
                    }

                }
                th {
                    font-weight: bold;
                    text-align: center;
                }
                td.cat {
                    text-align: center;
                }
            }
        }
    }
    .keti-chart {
        background-color: #FFF;
        position: fixed;
        z-index: 900;
        width: 100%;
        top:50px;
        bottom:0;        
        padding: 10px;
        padding-top: 100px;
        overflow-y: auto;
        white-space: pre-line;
        line-height: 200%;


        .infoIcon {
            text-align: center;
            font-size: 30px;
        }

        .keti-chart-pos-C {
            margin: 0 auto;
            width: 70%;
        }
        .keti-chart-node {
            text-align: center;
            padding: 5px;
            margin: 3px;
            border-radius: 5px;
            font-size: 12px;
            line-height: 170%;
        }
        .keti-chart-table {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
        }

    }

    .node-c, .node-d, .node-w, .node-b, .node-rs, .node-r {
        cursor: pointer;
    }

    .node-desc {
        position:absolute;
        display: inline-block;
        font-size: 11px;
        background-color: #333;
        color: #FFF;
        width: 75px;
        text-align: center;
        margin-top:-4px;
        border-radius: 5px;
        padding: 1px 5px;
        line-height: 12px;
    }

    .node-c {
        color: #934DF6;
        background-color: #EBE1F5;
        box-shadow: inset 0 -1px 0 #4F1882;
        cursor: pointer;
    }
    .node-d {
        color: #09857C;
        background-color: #D7F5F3;
        box-shadow: inset 0 -1px 0 #03423E;
    }
    .node-w {
        color: #2E6FD9;
        background-color: #E6EEFC;
        box-shadow: inset 0 -1px 0 #0F397D;
    }
    .node-b {
        color: #457D99;
        background-color: #EBF3F7;
        box-shadow: inset 0 -1px 0 #1D3947;
    }
    .node-rs {
        color: #B36200;
        background-color: #FCF0E1;
        box-shadow: inset 0 -1px 0 #6E3C00;
    }
    .node-r {
        color: #D42828;
        background-color: #FCDCDC;
        box-shadow: inset 0 -1.3px 0 #750606;
    }

    .flex {
        display: flex;
    }

    .sm:flex-row {
        flex-direction: row;
    }
    .justify-start {
        justify-content: flex-start;
    }
    .items-center {
        align-items: center;
    }
    .flex-col {
        flex-direction: column;
    }
    .bg-white {
        --bg-opacity: 1;
        background-color: #fff;
        background-color: rgba(255,255,255,var(--bg-opacity));
    }
    .relative {
        position: relative;
    }
    .flex-grow {
        flex-grow: 1;
    }
    .w-4 {
        width: 1rem;
    }
    .absolute {
        position: absolute;
    }
    .pl-8 {
        padding-left: 2rem;
    }
    .w-full {
        width: 100%;
    }
    .btn {
        background-color: #FFF;
        border: 1px solid #333;
        border-radius: 3px;
    }

</style>