<template>
  <div class="home">
    <TMEditor></TMEditor>
  </div>
</template>

<script>
// @ is an alias to /src
import TMEditor from '@/components/common/TMEditor.vue'

export default {
  name: 'home',
  layout:"Annotation",
  components: {
    TMEditor
  }
}
</script>
<style lang="scss" scoped>
.viewer {
    border:1px solid #ddd;
    outline: none;
    padding: 5px;
    margin: 5px;
}
</style>